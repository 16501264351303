import {
    ITEM_AVAILABILITY_UPDATE_FAIL,
    ITEM_AVAILABILITY_UPDATE_REQUEST, ITEM_AVAILABILITY_UPDATE_SUCCESS,
    LIST_MENU_FAIL,
    LIST_MENU_REQUEST,
    LIST_MENU_SUCCESS, MENU_ADD_FAIL, MENU_ADD_REQUEST, MENU_ADD_SUCCESS,
    MENU_UPDATE_FAIL,
    MENU_UPDATE_REQUEST,
    MENU_UPDATE_SUCCESS
} from "./constants";
export const listMenuReducer = (state = {}, action) => {
    switch (action.type) {
        case LIST_MENU_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case LIST_MENU_SUCCESS: {
            return {
                ...state,
                menu: action.payload.data,
                loading: false,
                error: null,
            }
        }
        case LIST_MENU_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const menuUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case MENU_UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case MENU_UPDATE_SUCCESS:
            return {
                ...state,
                menu: action.payload,
                loading: false,
                error: null,
                success: true
            };
        case MENU_UPDATE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};
export const menuAddReducer = (state = {}, action) => {
    switch (action.type) {
        case MENU_ADD_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case MENU_ADD_SUCCESS:
            return {
                ...state,
                menu: action.payload,
                loading: false,
                error: null,
                success: true
            };
        case MENU_ADD_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};


export const itemAvailabilityUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case ITEM_AVAILABILITY_UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                success: false,
            };
        case ITEM_AVAILABILITY_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                item: action.payload,
                error: null,
            };
        case ITEM_AVAILABILITY_UPDATE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
            };
        default:
            return state;
    }
};
