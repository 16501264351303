export const buildQueryString = (params) => {
  const queryString = Object.keys(params)
      .map(key => {
        if (typeof params[key] === 'object' && params[key] !== null) {
          return Object.keys(params[key])
              .map(subKey => `${encodeURIComponent(key)}[${encodeURIComponent(subKey)}]=${encodeURIComponent(params[key][subKey])}`)
              .join('&');
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
      })
      .join('&');
  return queryString;
};

// Example usage:
// const filterValues = {
//   orderID: '1234',
//   status: 'pending',
//   'startDate[gte]': '2021-01-01',
//   'endDate[lte]': '2021-12-31',
//   page: 10
// };
// const queryString = buildQueryString(filterValues);
// console.log(queryString);
