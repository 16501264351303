export const LIST_ORDERS_REQUEST = 'LIST_ORDERS_REQUEST';
export const LIST_ORDERS_SUCCESS = 'LIST_ORDERS_SUCCESS';
export const LIST_ORDERS_FAILURE = 'LIST_ORDERS_FAILURE';

export const LIST_TABLES_REQUEST = 'LIST_TABLES_REQUEST';
export const LIST_TABLES_SUCCESS = 'LIST_TABLES_SUCCESS';
export const LIST_TABLES_FAILURE = 'LIST_TABLES_FAILURE';

export const CREATE_TABLES_REQUEST = 'CREATE_TABLES_REQUEST';
export const CREATE_TABLES_SUCCESS = 'CREATE_TABLES_SUCCESS';
export const CREATE_TABLES_FAILURE = 'CREATE_TABLES_FAILURE';

export const DOWNLOAD_TABLE_QR_CODE_REQUEST = 'DOWNLOAD_TABLE_QR_CODE_REQUEST';
export const DOWNLOAD_TABLE_QR_CODE_SUCCESS = 'DOWNLOAD_TABLE_QR_CODE_SUCCESS';
export const DOWNLOAD_TABLE_QR_CODE_FAILURE = 'DOWNLOAD_TABLE_QR_CODE_FAILURE';

export const GET_ORDER_REQUEST = 'GET_ORDER_REQUEST';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAILURE = 'GET_ORDER_FAILURE';

export const UPDATE_ORDER_REQUEST = 'UPDATE_ORDER_REQUEST';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAILURE = 'UPDATE_ORDER_FAILURE';

export const GET_RECEIPT_REQUEST = 'GET_RECEIPT_REQUEST'
export const GET_RECEIPT_SUCCESS = 'GET_RECEIPT_SUCCESS'
export const GET_RECEIPT_FAILURE = 'GET_RECEIPT_FAILURE'