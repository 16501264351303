import React, {useEffect, useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  FaStore,
  FaMoneyBillWave,
  FaPhone,
  FaEnvelope,
  FaCalendarAlt,
  FaChair,
  FaLock,
  FaUtensils,
  FaTag,
  FaCheckCircle,
  FaTable,
  FaBuilding,
  FaInfoCircle
} from 'react-icons/fa';
import { IoFastFood } from 'react-icons/io5';
import { VscGraph } from 'react-icons/vsc';
import {
  getEstablishmentAction,
  getEstablishmentBranchAction,
  getMiscAnalyticsAction,
  updateEstablishmentAction,
} from '../../redux/establishment/actions';
import Sidebar from '../../components/Sidebar';

const ToggleSwitch = ({ isChecked, onChange }) => (
    <label className="relative inline-flex items-center cursor-pointer">
      <input type="checkbox" checked={isChecked} onChange={onChange} className="sr-only peer" />
      <div className="w-11 h-6 bg-gray-200  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
    </label>
);

const Dashboard = () => {
  const dispatch = useDispatch();

  const updateEstablishmentReducer = useSelector(state => state?.updateEstablishmentReducer);
  const establishment = useSelector(state => state?.getEstablishmentReducer?.establishment);
  const establishmentBranch = useSelector(state => state?.getEstablishmentBranchReducer?.establishmentBranch);
  const miscAnalytics = useSelector(state => state?.getMiscAnalyticsReducer?.analytics);

  const [allowsOnlinePayments, setAllowsOnlinePayments] = useState(establishmentBranch?.allowsOnlinePayments);
  const [allowsCashPayments, setAllowsCashPayments] = useState(establishmentBranch?.allowsCashPayments);
  const [allowsPickup, setAllowsPickup] = useState(establishmentBranch?.allowsPickup);
  const [allowsDineIn, setAllowsDineIn] = useState(establishmentBranch?.allowsDineIn);
  const [acceptingOrders, setAcceptingOrders] = useState(establishmentBranch?.acceptingOrders);

  const isInitialized = useRef(false);

  useEffect(() => {
    if (establishmentBranch && !isInitialized.current) {
      setAllowsOnlinePayments(establishmentBranch.allowsOnlinePayments);
      setAllowsCashPayments(establishmentBranch.allowsCashPayments);
      setAllowsPickup(establishmentBranch.allowsPickup);
      setAllowsDineIn(establishmentBranch.allowsDineIn);
      setAcceptingOrders(establishmentBranch.acceptingOrders);
      isInitialized.current = true; // Mark as initialized
    }
  }, [establishmentBranch]);


  useEffect(() => {
    if(updateEstablishmentReducer.loading !== true) {
      dispatch(getEstablishmentAction());
      dispatch(getEstablishmentBranchAction());
      dispatch(getMiscAnalyticsAction({branchId: parseInt(localStorage?.getItem("branchID"))}));
    }
  }, [dispatch, updateEstablishmentReducer.loading]);

  const handleToggleChange = (key, value) => {
    switch (key) {
      case 'allowsOnlinePayments':
        setAllowsOnlinePayments(value);
        break;
      case 'allowsCashPayments':
        setAllowsCashPayments(value);
        break;
      case 'allowsPickup':
        setAllowsPickup(value);
        break;
      case 'allowsDineIn':
        setAllowsDineIn(value);
        break;
      case 'acceptingOrders':
        setAcceptingOrders(value);
        break;
      default:
        break;
    }
    const settings = {
      allowsOnlinePayments: key === 'allowsOnlinePayments' ? value : allowsOnlinePayments,
      allowsCashPayments: key === 'allowsCashPayments' ? value : allowsCashPayments,
      allowsPickup: key === 'allowsPickup' ? value : allowsPickup,
      allowsDineIn: key === 'allowsDineIn' ? value : allowsDineIn,
      acceptingOrders: key === 'acceptingOrders' ? value : acceptingOrders,
    };
    const updatedBranchSettings = { ...establishmentBranch, ...settings };
    dispatch(updateEstablishmentAction(updatedBranchSettings, true));
  };

  return (
      <>
        <Helmet>
          <title>Dashboard - Serv Restaurant Interface</title>
          <meta property="og:title" content="Dashboard - Serv Restaurant Interface" />
        </Helmet>
        <Sidebar />
        <div className="flex h-screen font-sans text-lg">
          <div className="flex-1 bg-[#fff7e1] ml-80 p-8 overflow-auto">
            <div className="bg-[#fff7e1] p-6 rounded  space-y-8">
              <div className="flex items-center mb-8">
                <div className="w-16 h-16 bg-blue-900 rounded-full">
                  {establishment && establishment?.logoUrl &&
                      <img src={establishment?.logoUrl} alt="Logo" className="w-full h-full object-cover rounded-full"/>
                  }
                </div>
                <div className="ml-4">
                  <h2 className="text-5xl text-[#151e46] font-bold">
                    {establishment && establishment?.establishmentName}
                  </h2>
                  <p className="text-[#fff7e1] text-xl">
                    {establishmentBranch && establishmentBranch?.branchDisplayName}
                  </p>
                </div>
              </div>

              <div className="bg-[#d7f1df] p-6 rounded-xl space-y-6">
                <h3 className="text-4xl font-bold text-[#40B06D] flex items-center space-x-4 mb-6">
                  <FaStore className="text-[#151e46] text-3xl"/>
                  <span className="text-3xl text-[#151e46] font-bold">Restaurant Information</span>
                </h3>
                <div className="space-y-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <div className="flex items-center space-x-2">
                        <FaUtensils className="text-[#151e46] text-2xl"/>
                        <p className="text-[#151e46] font-bold text-xl">Restaurant Name: </p>
                        <p className="text-[#151e46] text-xl">
                        {establishment && establishment?.establishmentName}
                      </p>
                      </div>
                     
                    </div>
                    <div>
                      <div className="flex items-center space-x-2">
                        <FaEnvelope className="text-[#151e46] text-2xl"/>
                        <p className="text-[#151e46] font-bold text-xl">Email:</p>
                        <p className="text-[#151e46] text-xl">
                        {JSON?.parse(localStorage?.getItem("userInfo"))?.username}
                      </p>
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="flex items-center space-x-2">
                        <FaInfoCircle className="text-[#151e46] text-2xl"/>
                        <p className="text-[#151e46] font-bold text-xl">About:</p>
                        <p className="text-[#151e46] text-xl">
                        {establishment && establishment?.description}
                      </p>
                      </div>
                      
                    </div>

                    <div>
                      <div className="flex items-center space-x-2">
                        <FaPhone className="text-[#151e46] text-2xl"/>
                        <p className="text-[#151e46] font-bold text-xl">Phone:</p>
                        <p className="text-[#151e46] text-xl">
                        {JSON?.parse(localStorage?.getItem("userInfo"))?.phoneNumber}
                      </p>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
              {localStorage?.getItem('branchID') !== "0" &&
              <div className="bg-[#d7f1df] p-6 rounded-xl space-y-6">
                <h3 className="text-4xl font-bold text-[#40B06D] flex items-center space-x-4 mb-6">
                  <FaStore className="text-[#151e46] text-3xl"/>
                  <span className="text-3xl text-[#151e46] font-bold">Branch Information</span>
                </h3>
                <div className="space-y-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <div className="flex items-center space-x-2">
                        <FaBuilding className="text-[#151e46] text-2xl"/>
                        <p className="text-[#151e46] font-bold text-xl">Branch ID:</p>
                        <p className="text-[#151e46] text-xl">
                        {establishmentBranch && establishmentBranch?.id}
                      </p>
                      </div>
                      
                    </div>
                    <div>
                      <div className="flex items-center space-x-2">
                        <FaTable className="text-[#151e46] text-2xl"/>
                        <p className="text-[#151e46] font-bold text-xl">Number of Tables:</p>
                        <p className="text-[#151e46] text-xl">
                          {establishmentBranch && establishmentBranch?.numTables}
                        </p>
                      </div>
                      
                    </div>
                    <div>
                      <div className="flex items-center space-x-2">
                        <FaCheckCircle className="text-[#151e46] text-2xl"/>
                        <p className="text-[#151e46] font-bold text-xl">Active:</p>
                        <p className="text-[#151e46] text-xl">
                        {establishmentBranch && (establishmentBranch?.active ? 'Yes' : 'No')}
                      </p>
                      </div>
                      
                    </div>
                    <div>
                      <div className="flex items-center space-x-2">
                        <FaCalendarAlt className="text-[#151e46] text-2xl"/>
                        <p className="text-[#151e46] font-bold text-xl">Branch Created On:</p>
                        <p className="text-[#151e46] text-xl">
                        {establishmentBranch && new Date(establishmentBranch?.branchCreatedAt)?.toLocaleDateString()}
                      </p>
                      </div>
                      
                    </div>
                    <div>
                      <div className="flex items-center space-x-2">
                        <FaTag className="text-[#151e46] text-2xl"/>
                        <p className="text-[#151e46] font-bold text-xl">Branch Display As:</p>
                        <p className="text-[#151e46] text-xl">
                        {establishmentBranch && establishmentBranch?.addressDisplayAs}
                      </p>
                      </div>
                      
                    </div>
                    <div>
                      <div className="flex items-center space-x-2">
                        <FaCalendarAlt className="text-[#151e46] text-2xl"/>
                        <p className="text-[#151e46] font-bold text-xl">Starting time:</p>
                        <p className="text-[#151e46] text-xl">
                          {establishmentBranch && establishmentBranch?.opensAt}
                        </p>
                      </div>
                      
                    </div>
                    <div>
                      <div className="flex items-center space-x-2">
                        <FaCalendarAlt className="text-[#151e46] text-2xl"/>
                        <p className="text-[#151e46] font-bold text-xl">Closing time:</p>
                        <p className="text-[#151e46] text-xl">
                          {establishmentBranch && establishmentBranch?.closesAt}
                        </p>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
              }

              {localStorage?.getItem('branchID') !== "0" &&
                  <div className="bg-[#d7f1df] p-6 rounded-xl">
                    <h3 className="text-3xl text-[#151e46] font-bold mb-4 flex items-center">
                      <FaLock className="mr-4 text-[#151e46] text-3xl"/> Branch Settings
                    </h3>
                    <div className="grid grid-cols-2 gap-4">
                      <div className="flex items-center">
                        <FaMoneyBillWave className="mr-2 text-[#151e46] text-2xl"/>
                        <p className="text-[#151e46] font-bold mr-4 text-xl">Online Payment</p>
                        <ToggleSwitch
                            isChecked={allowsOnlinePayments}
                            onChange={() => handleToggleChange('allowsOnlinePayments',!allowsOnlinePayments)}
                        />
                      </div>
                      <div className="flex items-center">
                        <FaMoneyBillWave className="mr-2 text-[#151e46] text-2xl"/>
                        <p className="text-[#151e46] font-bold mr-4 text-xl">Cash Payment</p>
                        <ToggleSwitch
                            isChecked={allowsCashPayments}
                            onChange={() => handleToggleChange('allowsCashPayments', !allowsCashPayments)}
                        />
                      </div>
                      <div className="flex items-center">
                        <IoFastFood className="mr-2 text-[#151e46] text-2xl"/>
                        <p className="text-[#151e46] font-bold mr-4 text-xl">Pickup Orders</p>
                        <ToggleSwitch
                            isChecked={allowsPickup}
                            onChange={() => handleToggleChange('allowsPickup',!allowsPickup)}
                        />
                      </div>
                      <div className="flex items-center">
                        <FaChair className="mr-2 text-[#151e46] text-2xl"/>
                        <p className="text-[#151e46] font-bold mr-4 text-xl">Dine In Orders</p>
                        <ToggleSwitch
                            isChecked={allowsDineIn}
                            onChange={() => handleToggleChange('allowsDineIn',!allowsDineIn)}
                        />
                      </div>
                      <div className="flex items-center">
                        <IoFastFood className="mr-2 text-[#151e46] text-2xl"/>
                        <p className="text-[#151e46] font-bold mr-4 text-xl">Accepting Orders</p>
                        <ToggleSwitch
                            isChecked={acceptingOrders}
                            onChange={() => handleToggleChange('acceptingOrders',!acceptingOrders)}
                        />
                      </div>
                    </div>
                  </div>
              }

              <div className="bg-[#d7f1df] p-6 rounded-xl">
                <h3 className="text-3xl text-[#151e46] font-bold mb-4 flex items-center">
                  <VscGraph className="mr-4 text-[#151e46] text-3xl"/> Analytics
                </h3>
                <div className="grid grid-cols-2 gap-4">
                  <div className="bg-[#d7f1df] p-4 rounded-lg flex items-center">
                    <FaMoneyBillWave className="mr-2 text-[#151e46] text-2xl"/>
                    <p className="text-[#151e46] text-xl font-bold mr-4">Total Revenue</p>
                    <p className="text-[#151e46] text-xl">
                      EGP {miscAnalytics && miscAnalytics?.totalGenerated}
                    </p>
                  </div>
                  <div className="bg-[#d7f1df] p-4 rounded-lg flex items-center">
                    <FaMoneyBillWave className="mr-2 text-[#151e46] text-2xl"/>
                    <p className="text-[#151e46] text-xl font-bold mr-4">Online Payment Revenue</p>
                    <p className="text-[#151e46] text-xl">
                      EGP {miscAnalytics && miscAnalytics?.totalPaidGross}
                    </p>
                  </div>
                  <div className="bg-[#d7f1df] p-4 rounded-lg flex items-center">
                    <FaMoneyBillWave className="mr-2 text-[#151e46] text-2xl"/>
                    <p className="text-[#151e46] text-xl font-bold mr-4">Available Balance</p>
                    <p className="text-[#151e46] text-xl">
                      EGP {miscAnalytics && miscAnalytics?.availableBalance}
                    </p>
                  </div>
                  <div className="bg-[#d7f1df] p-4 rounded-lg flex items-center">
                    <FaMoneyBillWave className="mr-2 text-[#151e46] text-2xl"/>
                    <p className="text-[#151e46] text-xl font-bold mr-4">Online Payment Net</p>
                    <p className="text-[#151e46] text-xl">
                      EGP {miscAnalytics && miscAnalytics?.totalPaidGross}
                    </p>
                  </div>
                  <div className="bg-[#d7f1df] p-4 rounded-lg flex items-center">
                    <FaMoneyBillWave className="mr-2 text-[#151e46] text-2xl"/>
                    <p className="text-[#151e46] text-xl font-bold mr-4">Online processing fees</p>

                    <p className="text-[#151e46] text-xl">
                      EGP {miscAnalytics && miscAnalytics?.totalFees}
                    </p>
                  </div>
                  <div className="bg-[#d7f1df] p-4 rounded-lg flex items-center">
                    <FaMoneyBillWave className="mr-2 text-[#151e46] text-2xl"/>
                    <p className="text-[#151e46] text-xl font-bold mr-4">Total checked out</p>
                    <p className="text-[#151e46] text-xl">
                      EGP {miscAnalytics && miscAnalytics?.totalPaidGross}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  );
};

export default Dashboard;
