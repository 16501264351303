import axios from 'axios';
import {
    LIST_ORDERS_REQUEST,
    LIST_ORDERS_SUCCESS,
    LIST_ORDERS_FAILURE,
    LIST_TABLES_REQUEST,
    LIST_TABLES_SUCCESS,
    LIST_TABLES_FAILURE,
    GET_ORDER_REQUEST,
    GET_ORDER_SUCCESS,
    GET_ORDER_FAILURE,
    UPDATE_ORDER_FAILURE,
    UPDATE_ORDER_SUCCESS,
    UPDATE_ORDER_REQUEST,
    CREATE_TABLES_REQUEST,
    CREATE_TABLES_SUCCESS,
    CREATE_TABLES_FAILURE,
    DOWNLOAD_TABLE_QR_CODE_REQUEST,
    DOWNLOAD_TABLE_QR_CODE_SUCCESS,
    DOWNLOAD_TABLE_QR_CODE_FAILURE,
    GET_RECEIPT_REQUEST,
    GET_RECEIPT_SUCCESS,
    GET_RECEIPT_FAILURE
} from './constants';
import baseURL from "../baseURL";

import { saveAs } from 'file-saver';
export const listOrdersAction = (queryStr) => async (dispatch) => {
    try {
        dispatch({
            type: LIST_ORDERS_REQUEST,
        });
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        };

        if (localStorage.getItem('branchID') !== "0")
            queryStr += `&branch_id=${localStorage.getItem('branchID')}`

        const {data} = await axios.get(`${baseURL}/orders/est?${queryStr}`, config);

        dispatch({
            type: LIST_ORDERS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: LIST_ORDERS_FAILURE,
            payload: error.response
                ? error.response.data.message
                : 'Network Error',
        });
    }
};

export const listTablesAction = (queryStr) => async (dispatch) => {
    try {
        dispatch({
            type: LIST_TABLES_REQUEST,
        });
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        };

        if (localStorage.getItem('branchID') !== "0")
            queryStr += `&branch_id=${localStorage.getItem('branchID')}`
        const {data} = await axios.get(`${baseURL}/tables?${queryStr}`, config);

        dispatch({
            type: LIST_TABLES_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: LIST_TABLES_FAILURE,
            payload: error.response
                ? error.response.data.message
                : 'Network Error',
        });
    }
};


export const getOrderAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: GET_ORDER_REQUEST,
        });
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        };

        const {data} = await axios.get(`${baseURL}/orders/${id}`, config);

        dispatch({
            type: GET_ORDER_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_ORDER_FAILURE,
            payload: error.response
                ? error.response.data.message
                : 'Network Error',
        });
    }
};

export const getReceiptViewAction = (id) => async (dispatch) => {
    try{
        dispatch({
            type : GET_RECEIPT_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        };

        const {data} = await axios.get(`${baseURL}/orders/${id}/items/receipt`, config);

        dispatch({
            type: GET_RECEIPT_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_RECEIPT_FAILURE,
            payload: error.response
                ? error.response.data.message
                : 'Network Error',
        });
    }
}

export const updateOrderAction = (id, body) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_ORDER_REQUEST,
        });
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        };

        const {data} = await axios.patch(`${baseURL}/orders/est/${id}`, body, config);

        dispatch({
            type: UPDATE_ORDER_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_ORDER_FAILURE,
            payload: error.response
                ? error.response.data.message
                : 'Network Error',
        });
    }
};

export const createTablesAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: CREATE_TABLES_REQUEST,
        });
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        };

        const {data} = await axios.post(`${baseURL}/tables`, body, config);

        dispatch({
            type: CREATE_TABLES_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CREATE_TABLES_FAILURE,
            payload: error.response
                ? error.response.data.message
                : 'Network Error',
        });
    }
};

export const downloadTableQRCodeAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: DOWNLOAD_TABLE_QR_CODE_REQUEST,
        });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
            responseType: 'blob'
        };

        const { data, headers } = await axios.post(`${baseURL}/misc/qr`, body, config);

        // Check if the response is a PNG image
        if (headers['content-type'] === 'image/png') {
            const fileName = 'QRCode.png';
            saveAs(data, fileName);  // Automatically download the file
        }

        dispatch({
            type: DOWNLOAD_TABLE_QR_CODE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: DOWNLOAD_TABLE_QR_CODE_FAILURE,
            payload: error.response
                ? error.response.data.message
                : 'Network Error',
        });
    }
};

