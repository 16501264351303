import {
    GET_ESTABLISHMENT_REQUEST,
    GET_ESTABLISHMENT_SUCCESS,
    GET_ESTABLISHMENT_FAIL,
    GET_ESTABLISHMENT_BRANCH_REQUEST,
    GET_ESTABLISHMENT_BRANCH_SUCCESS,
    GET_ESTABLISHMENT_BRANCH_FAIL,
    GET_MISC_ANALYTICS_REQUEST,
    GET_MISC_ANALYTICS_SUCCESS,
    GET_MISC_ANALYTICS_FAIL,
    UPDATE_ESTABLISHMENT_REQUEST,
    UPDATE_ESTABLISHMENT_SUCCESS,
    UPDATE_ESTABLISHMENT_FAIL,
} from './constants';

export const getEstablishmentReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_ESTABLISHMENT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_ESTABLISHMENT_SUCCESS:
            return {
                ...state,
                establishment: action.payload,
                loading: false,
                error: null,
            };
        case GET_ESTABLISHMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getEstablishmentBranchReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_ESTABLISHMENT_BRANCH_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_ESTABLISHMENT_BRANCH_SUCCESS:
            return {
                ...state,
                establishmentBranch: action.payload,
                loading: false,
                error: null,
            };
        case GET_ESTABLISHMENT_BRANCH_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getMiscAnalyticsReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_MISC_ANALYTICS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_MISC_ANALYTICS_SUCCESS:
            return {
                ...state,
                analytics: action.payload,
                loading: false,
                error: null,
            };
        case GET_MISC_ANALYTICS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const updateEstablishmentReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_ESTABLISHMENT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case UPDATE_ESTABLISHMENT_SUCCESS:
            return {
                ...state,
                establishment: action.payload,
                loading: false,
                error: null,
            };
        case UPDATE_ESTABLISHMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};