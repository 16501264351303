import axios from "axios";
import baseURL from "../baseURL";
import {
    CREATE_FEEDBACK_FAIL,
    CREATE_FEEDBACK_REQUEST,
    CREATE_FEEDBACK_SUCCESS, LIST_ORDER_FEEDBACK_FAILURE,
    LIST_ORDER_FEEDBACK_REQUEST, LIST_ORDER_FEEDBACK_SUCCESS
} from "./constants";

export const createFeedbackAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: CREATE_FEEDBACK_REQUEST,
        });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        };
        const { data } = await axios.post(
            `${baseURL}/feedbacks/system`,
            body,
            config
        );

        dispatch({
            type: CREATE_FEEDBACK_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CREATE_FEEDBACK_FAIL,
            payload: error.response
                ? error.response.data.message
                : 'Login failed. Please try again.',
        });
    }
};

export const listOrderFeedbacksAction = (queryStr) => async (dispatch) => {
    try {
        dispatch({
            type: LIST_ORDER_FEEDBACK_REQUEST,
        });
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        };
        if(localStorage.getItem('branchID') !== "0")
            queryStr += `&branch_id=${localStorage.getItem('branchID')}`

        const { data } = await axios.get(`${baseURL}/feedbacks/orders?${queryStr}`,config);

        dispatch({
            type: LIST_ORDER_FEEDBACK_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: LIST_ORDER_FEEDBACK_FAILURE,
            payload: error.response
                ? error.response.data.message
                : 'Network Error',
        });
    }
};
