import React, { useState } from 'react';
import {Button} from "@tremor/react";
import {useDispatch, useSelector} from "react-redux";
import BottomCallout from "./BottomCallout";
import {createFeedbackAction} from "../redux/feedback/actions";

const FeedbackModal = ({onClose, setShowCallout}) => {
  const [rating, setRating] = useState(0);
  const [feedbackText, setFeedbackText] = useState('');

  const dispatch = useDispatch();
  const createFeedbackReducer = useSelector((state) => state.createFeedbackReducer);
  const { loading } = createFeedbackReducer;

  const handleStarClick = (index) => {
    setRating(index + 1);
  };
  const handleTextareaChange = (event) => {
    setFeedbackText(event.target.value);
  };

  const handleSubmit = () => {
      dispatch(createFeedbackAction({ rating, comment: feedbackText }));
      setShowCallout(true);
      onClose(false)

  };

  return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
        <div className="w-7/10 h-auto flex flex-col items-start rounded-lg bg-[#FFF7E1] p-4">
          <div role={"button"} onClick={()=>onClose()} className="w-full h-14 flex justify-end items-start gap-1">
            <img
                alt="pastedImage"
                src="/external/pastedimage-lop8.svg"
                className="w-10 mt-1 mr-1"
            />
          </div>
          <div className="w-full flex flex-col items-center justify-center gap-1 p-1 mt-0 mb-2">
            <span className="text-4xl font-bold text-orange-600">We value your feedback</span>
            <span className="text-xl font-medium text-center text-[#151e46]">
            Please share your thoughts with us to help improve your experience.
          </span>
          </div>
          <div className="w-full flex flex-col items-center justify-center mb-2">
            <div className="flex items-center justify-center">
              {[...Array(5)].map((_, index) => (
                  <img
                      key={index}
                      alt="star"
                      src={index < rating ? '/external/pastedimage-l9na.svg' : '/external/pastedimage-a9qm.svg'}
                      className="w-10 cursor-pointer"
                      onClick={() => handleStarClick(index)}
                  />
              ))}
            </div>
          </div>
          <div className="w-full h-77 flex flex-col items-center justify-start pl-12 pr-12">
            <div className="w-full h-64 flex flex-col items-center justify-start pt-1 pb-1 px-1">
            <textarea
                value={feedbackText}
                onChange={handleTextareaChange}
                placeholder="Please describe your experience or suggestions in detail here..."
                className="w-full h-48 bg-[#FFF7E1] border-2 border-dark-blue-900 text-dark-blue-900 rounded-md px-6"
            ></textarea>
                      </div>
          </div>
          <div className="w-full flex items-center justify-center mt-1 mb-1 pt-1 pb-1 px-4">
            <Button
                onClick={handleSubmit}
                loading={loading}
                className="w-42 h-11 flex items-center text-xl text-[#FFF7E1] justify-center rounded-lg bg-orange-600 cursor-pointer">
              Submit
            </Button>
          </div>
        </div>
      </div>
  );
};

export default FeedbackModal;
