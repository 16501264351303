import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEstablishmentAction } from '../redux/establishment/actions';

const SelectBranchModal = ({ onClose, canClose }) => {
  const dispatch = useDispatch();
  const branchesData = useSelector((state) => state.getEstablishmentReducer);
  const [selectedBranch, setSelectedBranch] = useState(null);

  const branches = useMemo(() => branchesData?.establishment?.branches || [], [branchesData]);

  // Fetch branches on component mount
  useEffect(() => {
    // Dispatch the action immediately on mount
    dispatch(getEstablishmentAction());

    // Set up the interval to check and re-dispatch as fallback
    const intervalId = setInterval(() => {
      if (branches?.establishment?.branches?.length === 0) {
        dispatch(getEstablishmentAction());
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, [dispatch, branches?.establishment?.branches]);

  // Memoized branches to avoid unnecessary re-renders

  // Set default branch for requests needing a branch no matter what
  const handleBranchSelect = (branchId, name) => {
    setSelectedBranch(branchId);
    localStorage.setItem('branchID', branchId);
    localStorage.setItem('branchName', name);
    if (branches.length > 0) {
      localStorage.setItem('defaultBranchID', branches[0].id);
    }
  };

  return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-[#FFF7E1] rounded-xl w-11/12 md:w-3/4 lg:w-2/3 xl:w-1/2 p-6">
          <div className="flex justify-end mb-4">
            {canClose && (
                <img
                    alt="Close"
                    src="/escape-svgrepo-com.svg"
                    className="w-10 cursor-pointer"
                    onClick={onClose}
                />
            )}
          </div>
          <div className="text-center mb-6">
            <h1 className="text-[#40B06D] text-4xl font-bold">Select Branch</h1>
            <p className="text-[#151e46] text-xl font-medium">Choose the branch you wish to manage or view</p>
          </div>
          <div className="mb-6 overflow-x-auto flex justify-center space-x-4 scrollbar-thin scrollbar-thumb-[#40B06D] scrollbar-track-[#FFF7E1]">
            <div className="flex space-x-4">
              {branches.length > 0 ? (
                  branches.map((branch) => (
                      <div
                          key={branch.id}
                          className={`flex-shrink-0 flex flex-col items-center bg-[#D7F1DF] rounded-lg p-4 w-48 border-4 cursor-pointer ${
                              selectedBranch === branch.id ? 'border-[#40B06D]' : 'border-transparent hover:border-[#40B06D]'
                          }`}
                          onClick={() => handleBranchSelect(branch.id, branch.address_display_as)}
                      >
                        <img alt="Branch" src="/map-pin-alt-svgrepo-com.svg" className="w-24 mb-2" />
                        <span className="text-[#151e46] text-xl font-medium text-center">
                    {branch.address_display_as}
                  </span>
                      </div>
                  ))
              ) : (
                  <div className="text-[#151e46] text-xl font-medium">Loading branches...</div>
              )}
              {
                branches.length > 0 &&
                    <div
                  key={0}
                  className={`flex-shrink-0 flex flex-col items-center bg-[#D7F1DF] rounded-lg p-4 w-48 border-4 cursor-pointer ${
                      selectedBranch === 0 ? 'border-[#40B06D]' : 'border-transparent hover:border-[#40B06D]'
                  }`}
                  onClick={() => handleBranchSelect(0)}
              >
                <img alt="Branch" src="/map-pin-alt-svgrepo-com.svg" className="w-24 mb-2" />
                <span className="text-[#151e46] text-xl font-medium text-center">General View</span>
              </div>
              }
            </div>
          </div>
          <div className="flex justify-end items-center">
            <button
                role="button"
                  disabled={branches.length == 0 }
                onClick={onClose}
                className="bg-[#40B06D] text-center text-[#FFF7E1] text-xl rounded-full px-6 py-2 cursor-pointer"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
  );
};

export default SelectBranchModal;
