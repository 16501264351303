import {
    IS_LOGGED_IN_PASS_FAIL,
    IS_LOGGED_IN_PASS_REQUEST,
    IS_LOGGED_IN_PASS_SUCCESS,
    RESET_PASS_FAIL,
    RESET_PASS_REQUEST,
    RESET_PASS_SUCCESS,
    USER_FORGET_PASS_EMAIL_FAIL,
    USER_FORGET_PASS_EMAIL_REQUEST,
    USER_FORGET_PASS_EMAIL_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGOUT_FAIL,
    USER_LOGOUT_REQUEST,
    USER_LOGOUT_SUCCESS,
    VERIFY_OTP_FAIL,
    VERIFY_OTP_REQUEST,
    VERIFY_OTP_SUCCESS
} from "./constants";

export const loginReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case USER_LOGIN_SUCCESS: {
            return {
                ...state,
                user: action.payload,
                isAuthenticated: true,
                loading: false,
                error: null,
                message: "Login successful"
            }
        };
        case USER_LOGIN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const forgetPasswordEmailReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_FORGET_PASS_EMAIL_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case USER_FORGET_PASS_EMAIL_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: null,
                success: true,
                message: "OTP sent successfully",
                id: action.payload.id
            }
        }
        case USER_FORGET_PASS_EMAIL_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const verifyOTPReducer = (state = {}, action) => {
    switch (action.type) {
        case VERIFY_OTP_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case VERIFY_OTP_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: null,
                success: true,
                message: action.payload.message
            }
        }
        case VERIFY_OTP_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const resetPasswordReducer = (state = {}, action) => {
    switch (action.type) {
        case RESET_PASS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case RESET_PASS_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: null,
                success: true,
                message: action.payload.message
            }
        }
        case RESET_PASS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const isLoggedInReducer = (state = {}, action) => {
    switch (action.type) {
        case IS_LOGGED_IN_PASS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case IS_LOGGED_IN_PASS_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: null,
                isAuthorised: true,
            }
        }
        case IS_LOGGED_IN_PASS_FAIL:
            return {
                ...state,
                loading: false,
                isAuthorised: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const logoutReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_LOGOUT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case USER_LOGOUT_SUCCESS: {
            return {
                ...state,
                loading: false,
                success: true,
                error: null,
            }
        }
        case USER_LOGOUT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};