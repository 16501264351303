export const GET_ESTABLISHMENT_SUCCESS = 'GET_ESTABLISHMENT_SUCCESS';
export const GET_ESTABLISHMENT_REQUEST = 'GET_ESTABLISHMENT_REQUEST';
export const GET_ESTABLISHMENT_FAIL = 'GET_ESTABLISHMENT_FAIL';


export const GET_ESTABLISHMENT_BRANCH_REQUEST = 'GET_ESTABLISHMENT_BRANCH_REQUEST';
export const GET_ESTABLISHMENT_BRANCH_SUCCESS = 'GET_ESTABLISHMENT_BRANCH_SUCCESS';
export const GET_ESTABLISHMENT_BRANCH_FAIL = 'GET_ESTABLISHMENT_BRANCH_FAIL';


export const GET_MISC_ANALYTICS_REQUEST = 'GET_MISC_ANALYTICS_REQUEST';
export const GET_MISC_ANALYTICS_SUCCESS = 'GET_MISC_ANALYTICS_SUCCESS';
export const GET_MISC_ANALYTICS_FAIL = 'GET_MISC_ANALYTICS_FAIL';

export const UPDATE_ESTABLISHMENT_REQUEST = 'UPDATE_ESTABLISHMENT_REQUEST';
export const UPDATE_ESTABLISHMENT_SUCCESS = 'UPDATE_ESTABLISHMENT_SUCCESS';
export const UPDATE_ESTABLISHMENT_FAIL = 'UPDATE_ESTABLISHMENT_FAIL';

