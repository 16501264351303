export const matchProtectedRoute = (pathname, protectedRoutes) => {
    return protectedRoutes.some(route => {
        const routeRegex = new RegExp(`^${route.replace(/:[^\s/]+/g, '([\\w-]+)')}$`);
        return routeRegex.test(pathname);
    });
};

export const checkPassword = (password, confirmPassword) => {
    if (password !== confirmPassword) {
        return 'Passwords do not match';
    }

    if (password.length < 8) {
        return 'Password must be at least 8 characters long';
    }

    const hasLetter = /[a-zA-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSymbol = /[^a-zA-Z0-9]/.test(password);

    if (!hasLetter || !hasNumber || !hasSymbol) {
        return 'Password must include letters, numbers, and symbols';
    }

    return '';
};