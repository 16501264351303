import axios from 'axios';
import {
    GET_ESTABLISHMENT_REQUEST,
    GET_ESTABLISHMENT_SUCCESS,
    GET_ESTABLISHMENT_FAIL,
    GET_ESTABLISHMENT_BRANCH_REQUEST,
    GET_ESTABLISHMENT_BRANCH_SUCCESS,
    GET_ESTABLISHMENT_BRANCH_FAIL,
    GET_MISC_ANALYTICS_SUCCESS,
    GET_MISC_ANALYTICS_FAIL,
    GET_MISC_ANALYTICS_REQUEST,
    UPDATE_ESTABLISHMENT_REQUEST,
    UPDATE_ESTABLISHMENT_SUCCESS,
    UPDATE_ESTABLISHMENT_FAIL,
} from './constants';
import baseURL from "../baseURL";

export const getEstablishmentAction = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_ESTABLISHMENT_REQUEST,
        });

        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        const establishmentId =  userInfo.establishmentID || userInfo.id;

        if (!establishmentId) {
            throw new Error('Establishment ID not found');
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        };

        const { data } = await axios.get(
            `${baseURL}/establishments/${establishmentId}`,
            config
        );

        dispatch({
            type: GET_ESTABLISHMENT_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_ESTABLISHMENT_FAIL,
            payload: error.response
                ? error.response.data.message
                : 'Network Error',
        });
    }
};

export const getEstablishmentBranchAction = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_ESTABLISHMENT_BRANCH_REQUEST,
        });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        };

        const { data } = await axios.get(
            `${baseURL}/establishments/branches/${localStorage.getItem("branchID")}`,
            config
        );

        dispatch({
            type: GET_ESTABLISHMENT_BRANCH_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_ESTABLISHMENT_BRANCH_FAIL,
            payload: error.response
                ? error.response.data.message
                : 'Network Error',
        });
    }
};

export const getMiscAnalyticsAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: GET_MISC_ANALYTICS_REQUEST,
        });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        };

        const { data } = await axios.post(
            `${baseURL}/misc/analytics`,
            body,
            config
        );

        dispatch({
            type: GET_MISC_ANALYTICS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_MISC_ANALYTICS_FAIL,
            payload: error.response
                ? error.response.data.message
                : 'Network Error',
        });
    }
};

export const updateEstablishmentAction = (body, updateBranch) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_ESTABLISHMENT_REQUEST,
        });

        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        const establishmentId =  userInfo.establishmentID || userInfo.id;
        let reqBody = {}
        if(updateBranch) {
            reqBody.branchID = parseInt(localStorage.getItem("branchID"))
            reqBody.branch = body
        }

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            withCredentials: true,
        };
        const formData = new FormData()
        formData.append('data', JSON.stringify(reqBody))

        const { data } = await axios.patch(
            `${baseURL}/establishments/${establishmentId}`,
            formData,
            config
        );

        dispatch({
            type: UPDATE_ESTABLISHMENT_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_ESTABLISHMENT_FAIL,
            payload: error.response
                ? error.response.data.message
                : 'Network Error',
        });
    }
};