import axios from 'axios';
import baseURL from '../baseURL'
import {
    IS_LOGGED_IN_PASS_FAIL,
    IS_LOGGED_IN_PASS_REQUEST,
    IS_LOGGED_IN_PASS_SUCCESS,
    RESET_PASS_FAIL,
    RESET_PASS_REQUEST,
    RESET_PASS_SUCCESS,
    USER_FORGET_PASS_EMAIL_FAIL,
    USER_FORGET_PASS_EMAIL_REQUEST,
    USER_FORGET_PASS_EMAIL_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGOUT_FAIL,
    USER_LOGOUT_REQUEST,
    USER_LOGOUT_SUCCESS,
    VERIFY_OTP_FAIL,
    VERIFY_OTP_REQUEST,
    VERIFY_OTP_SUCCESS
} from "./constants";
export const loginAction = (username, password) => async (dispatch) => {
    try {
        dispatch({
            type: USER_LOGIN_REQUEST,
        });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        };
        const { data } = await axios.post(
            `${baseURL}/users/login`,
            { username, password },
            config
        );

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data,
        });

        localStorage.setItem('userInfo', JSON.stringify(data));
    } catch (error) {
        dispatch({
            type: USER_LOGIN_FAIL,
            payload: error.response
                ? error.response.data.message
                : 'Login failed. Please try again.',
        });
    }
};
export const forgetPasswordEmailAction = (email) => async (dispatch) => {
    try {
        dispatch({
            type: USER_FORGET_PASS_EMAIL_REQUEST,
        });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        };
        const { data } = await axios.put(
            `${baseURL}/users/forget-password`,
            { email },
            config
        );

        dispatch({
            type: USER_FORGET_PASS_EMAIL_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: USER_FORGET_PASS_EMAIL_FAIL,
            payload: error.response
                ? error.response.data.message
                : 'Login failed. Please try again.',
        });
    }
};

export const verifyOTPAction = (id, otp) => async (dispatch) => {
    try {
        dispatch({
            type: VERIFY_OTP_REQUEST,
        });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        };
        const { data } = await axios.put(
            `${baseURL}/users/otp-submit`,
            { id, otp },
            config
        );

        dispatch({
            type: VERIFY_OTP_SUCCESS,
            payload: data,
        });

        localStorage.setItem('userInfo', JSON.stringify(data.user));
    } catch (error) {
        dispatch({
            type: VERIFY_OTP_FAIL,
            payload: error.response
                ? error.response.data.message
                : 'Login failed. Please try again.',
        });
    }
};

export const resetPasswordAction = (password, passwordConfirm) => async (dispatch) => {
    try {
        dispatch({
            type: RESET_PASS_REQUEST,
        });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        };
        const { data } = await axios.patch(
            `${baseURL}/users/reset-password`,
            { password, passwordConfirm },
            config
        );

        dispatch({
            type: RESET_PASS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: RESET_PASS_FAIL,
            payload: error.response
                ? error.response.data.message
                : 'Login failed. Please try again.',
        });
    }
};

export const isLoggedInAction = () => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOGGED_IN_PASS_REQUEST,
        });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        };
        const { data } = await axios.get(
            `${baseURL}/users/is-authorised`,
            config
        );

        dispatch({
            type: IS_LOGGED_IN_PASS_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: IS_LOGGED_IN_PASS_FAIL,
            payload: error.response
                ? error.response.data.message
                : 'Login failed. Please try again.',
        });
    }
};

export const logoutAction = () => async (dispatch) => {
    try {
        dispatch({
            type: USER_LOGOUT_REQUEST,
        });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        };
        const { data } = await axios.post(
            `${baseURL}/users/logout`,{},
            config
        );
        localStorage.clear()

        dispatch({
            type: USER_LOGOUT_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: USER_LOGOUT_FAIL,
            payload: error.response
                ? error.response.data.message
                : 'Login failed. Please try again.',
        });
    }
};