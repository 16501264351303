import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import {buildQueryString} from "../utils/queryStringBuilder";

const FiltersModal = ({ title, subtitle, filters, onSubmit, buttonLabel }) => {
    const [filterValues, setFilterValues] = useState({});

    const handleInputChange = (name, value) => {
        setFilterValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const renderFilterField = (filter) => {
        switch (filter.type) {
            case 'text':
                return (
                    <input
                        type="text"
                        placeholder={filter.label}
                        className="p-2 border rounded text-[#151e46]"
                        value={filterValues[filter.queryString] || ''}
                        onChange={(e) => handleInputChange(filter.queryString, e.target.value)}
                    />
                );
            case 'dropdown':
                return (
                    <select
                        className="p-2 bg-[#fff7e1] text-[#151e46] border rounded"
                        value={filterValues[filter.queryString] || ''}
                        onChange={(e) => handleInputChange(filter.queryString, e.target.value)}
                    >
                        {filter?.options?.map((option, index) => (
                            <option key={index} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                );
            case 'date':
                return (
                    <input
                        type="date"
                        className="p-2 border text-[#151e46] rounded"
                        value={filterValues[filter.queryString] || ''}
                        onChange={(e) => handleInputChange(filter.queryString, e.target.value)}
                    />
                );
            case 'date-between':
                return (
                    <div className="flex space-x-2">
                        <input
                            type="date"
                            className="p-2 border text-[#151e46] rounded"
                            placeholder="Start Date"
                            value={filterValues[`${filter.queryString}[gte]`] || ''}
                            onChange={(e) => handleInputChange(`${filter.queryString}[gte]`, e.target.value)}
                        />
                        <input
                            type="date"
                            className="p-2 border text-[#151e46] rounded"
                            placeholder="End Date"
                            value={filterValues[`${filter.queryString}[lte]`] || ''}
                            onChange={(e) => handleInputChange(`${filter.queryString}[lte]`, e.target.value)}
                        />
                    </div>
                );
            default:
                return null;
        }
    };
    const handleClearFilters = () => {
        setFilterValues({});
        const queryString = buildQueryString(filterValues);
        onSubmit(queryString);
    };
    const handleFilterSubmit = () => {
        const queryString = buildQueryString(filterValues);
        onSubmit(queryString);
    };

    return (
        <Modal
            isOpen={true}
            className="bg-beige p-6 max-w-4xl mx-auto my-8"
            overlayClassName="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center"
        >
            <div className="bg-[#fff7e1] p-6 border-2 border-[#40b06d] rounded-lg">
                <div className="flex items-center mb-4">
                    <div>
                        <h2 className="text-[#40b06d] text-xl font-bold">{title}</h2>
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                    {filters?.map((filter, index) => (
                        <div key={index} className="flex flex-col">
                            <label className="mb-2 text-[#40b06d] font-medium">{filter.label}</label>
                            {renderFilterField(filter)}
                        </div>
                    ))}
                </div>
                <div className="flex justify-end mt-4">
                        <button
                            onClick={handleClearFilters}
                            className="bg-[#f0532f] text-white px-4 py-2 mx-2 rounded-3xl"
                        >
                            Clear Filters
                        </button>
                    <button
                        onClick={handleFilterSubmit}
                        className="bg-[#40b06d] text-white px-4 py-2 mx-2 rounded-3xl"
                    >
                        {buttonLabel}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

FiltersModal.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    filters: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.oneOf(['text', 'dropdown', 'date', 'date-between']).isRequired,
            label: PropTypes.string.isRequired,
            queryString: PropTypes.string.isRequired,
            options: PropTypes.arrayOf(
                PropTypes.shape({
                    value: PropTypes.string.isRequired,
                    label: PropTypes.string.isRequired,
                })
            ),
        })
    ).isRequired,
    onSubmit: PropTypes.func.isRequired,
    buttonLabel: PropTypes.string.isRequired,
};

FiltersModal.defaultProps = {
    subtitle: '',
};

export default FiltersModal;
