import React from 'react';
import PropTypes from 'prop-types';
import {FaArrowRight, FaExclamation, FaExclamationCircle, FaInfo, FaQrcode} from "react-icons/fa";
import {FaArrowRightArrowLeft} from "react-icons/fa6";
import {Link} from "react-router-dom";

// Reusable TableRow component

const colClasses = {
    1: 'grid-cols-1',
    2: 'grid-cols-2',
    3: 'grid-cols-3',
    4: 'grid-cols-4',
    5: 'grid-cols-5',
    6: 'grid-cols-6',
    7: 'grid-cols-7',
    8: 'grid-cols-8',
};
const TableRow = ({cols, len, baseRoute, id, onRowClick, item, qr, info}) => {
    return (
        <div className={`w-full flex items-center`}>
            {item.needsAction && (
                <FaExclamationCircle color={'#F0532F'} size={30} className="absolute items-center flex justify-center"/>)
            }
            <div className={`w-full items-center  grid grid-cols-${len}  pt-2 pl-2 pr-6 pb-2 my-2`}>

                {cols?.map((col, index) => (
                    <span key={index}
                          className="text-[#151E46] w-[150px] text-[22px] font-medium flex items-center justify-center col-span-1">
                    {col}
                </span>
                ))}
                {baseRoute && id ?
                    <Link to={`${baseRoute}/${id}`}
                          className="gap-2  w-[150px] text-[22px] font-medium flex items-center justify-center col-span-1">
                        <FaArrowRight color={"#40b06d"}/>
                    </Link> :
                    onRowClick && info ?
                    <div role={'button'} onClick={() => onRowClick(item)}
                         className="gap-2  w-[150px] text-[22px] font-medium flex items-center justify-center col-span-1">
                        <FaInfo color={"#40b06d"}/>
                    </div> :
                    <Link disabled to={`${baseRoute}/${id}`}
                          className="gap-2  w-[150px] text-[22px] font-medium flex items-center justify-center col-span-1">
                    </Link>

                }
                {
                }
                {qr &&
                    <div role={'button'} onClick={() => onRowClick(item.id, item.branchId)}
                         className="gap-2  w-[150px] text-[22px] font-medium flex items-center justify-center col-span-1">
                        <FaQrcode color={"#40b06d"}/>
                    </div>
                }

            </div>
        </div>
    );
};

function formatReadableDate(dateString) {
    const date = new Date(dateString);
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
    };
    return date.toLocaleString('en-US', options);
}

const Table = ({
                   data,
                   tabs,
                   cols,
                   attributeDisplayNames,
                   colNums,
                   baseRoute,
                   idFieldName,
                   page,
                   setPage,
                   onRowClick,
                   currentTab,
                   setTab,
                   maxPage,
                   countRows,
                   qr
               }) => {
    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= maxPage) {
            setPage(newPage);
        }
    };
    return (
        <div className="bg-[#FFF7E1] w-full flex flex-col relative text-center items-start">
            <div className="flex items-center">
                {tabs?.map((tab, index) => (
                    <div
                        role={'button'}
                        onClick={() => setTab(tab)}
                        key={index}
                        className={`w-[150px] h-[52px] flex items-center text-center border-2 border-[#40b06d] rounded-t-3xl ${
                            tab === currentTab ? 'bg-[#FFF7E1] text-[#40b06d]' : 'bg-[#40b06d] text-[#FFF7E1]'
                        }`}
                    >
                        <span className="text-[22px] px-3 font-semibold">{tab}</span>
                    </div>
                ))}
            </div>
            <div
                className={`w-full items-center justify-center pt-2 pl-2 pr-6 mb-8 py-3 grid ${colClasses[colNums]} bg-[#40b06d]`}>
                {attributeDisplayNames?.map((name, index) => (
                    <span key={index}
                          className="text-[#FFF7E1] w-[150px] text-[22px] font-medium flex items-center justify-center col-span-1">
            {name}
        </span>
                ))}
            </div>
            {data && data.length > 0 ? data?.map((item, index) => (
                <>
                    <TableRow
                        onRowClick={onRowClick}
                        item={item}
                        key={index}
                        baseRoute={baseRoute}
                        id={item[idFieldName]}
                        qr={qr}
                        info={item.comment}
                        cols={cols.map(colName => colName.endsWith('At') ? formatReadableDate(item[colName]) : item[colName] || '')}
                        len={colNums}
                    />
                    {index < data.length - 1 &&
                        <div className="w-full border-b border-gray-300 my-3"></div>
                    }
                </>
            )) : <div className="flex w-full flex-col items-center justify-center h-full py-20">

                <h1 className="text-2xl font-semibold text-[#151E46]">No records found !</h1>
            </div>}

            <div
                className="w-full flex items-center pt-2 pl-6 pr-6 pb-2 justify-between my-4 rounded-b-xl bg-[#40b06d]">
                <span className="text-[#FFF7E1] text-[22px] font-medium text-center">Total :</span>
                <span className="text-[#FFF7E1] text-[22px] font-medium text-center">{countRows}</span>
            </div>
            <div className="w-full flex bottom-0 justify-center">
                <div className="flex items-center p-3 bg-[#151e46] justify-center gap-4 rounded-3xl">
                    {/* Previous Button */}
                    <span
                        className={`text-[#FFF7E1] text-[22px] mx-3 font-medium text-center cursor-pointer ${page === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                        onClick={() => handlePageChange(page - 1)}
                    >
          {'<'}
        </span>

                    {/* Always show Page 1 */}
                    {page > 1 && (
                        <span
                            className={`text-[22px] mx-3 font-medium text-center cursor-pointer ${page === 1 ? 'bg-[#151e46] text-[#FFF7E1]' : 'text-[#FFF7E1]'}`}
                            onClick={() => handlePageChange(1)}
                        >
                {'1'}
            </span>
                    )}

                    {/* Show current and surrounding pages */}
                    {page > 2 && <span className="text-[#FFF7E1] text-[22px] mx-3 font-medium text-center">...</span>}
                    {page > 2 && (
                        <span
                            className={`text-[22px] mx-3 font-medium text-center cursor-pointer ${page === page - 1 ? 'text-[#40b06d]' : 'text-[#FFF7E1]'}`}
                            onClick={() => handlePageChange(page - 1)}
                        >
                {page - 1}
            </span>
                    )}

                    <span
                        className={`text-[22px] mx-3 font-medium text-center cursor-pointer ${page === page ? 'text-[#40b06d]' : 'text-[#FFF7E1]'}`}
                    >
            {page}
        </span>

                    {page < maxPage - 1 && (
                        <span
                            className={`text-[22px] mx-3 font-medium text-center cursor-pointer ${page === page + 1 ? 'text-[#40b06d]' : 'text-[#FFF7E1]'}`}
                            onClick={() => handlePageChange(page + 1)}
                        >
                {page + 1}
            </span>
                    )}
                    {page < maxPage - 1 &&
                        <span className="text-[#FFF7E1] text-[22px] mx-3 font-medium text-center">...</span>}

                    {/* Always show Last Page */}
                    {page < maxPage && (
                        <span
                            className={`text-[22px] mx-3 font-medium text-center cursor-pointer ${page === maxPage ? 'text-[#40b06d]' : 'text-[#FFF7E1]'}`}
                            onClick={() => handlePageChange(maxPage)}
                        >
                {maxPage}
            </span>
                    )}

                    {/* Next Button */}
                    <span
                        className={`text-[#FFF7E1] text-[22px] mx-3 font-medium text-center cursor-pointer ${page === maxPage ? 'opacity-50 cursor-not-allowed' : ''}`}
                        onClick={() => handlePageChange(page + 1)}
                    >
          {'>'}
        </span>
                </div>
            </div>

        </div>
    );
};

Table.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
    cols: PropTypes.arrayOf(PropTypes.string).isRequired,
    attributeDisplayNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    constantImageSrc: PropTypes.string.isRequired,
    constantImageSrc1: PropTypes.string.isRequired,
};

export default Table;
