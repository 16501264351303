import {
    CREATE_FEEDBACK_FAIL,
    CREATE_FEEDBACK_REQUEST,
    CREATE_FEEDBACK_SUCCESS, LIST_ORDER_FEEDBACK_FAILURE, LIST_ORDER_FEEDBACK_REQUEST,
    LIST_ORDER_FEEDBACK_SUCCESS
} from "./constants";
import {LIST_ORDERS_FAILURE, LIST_ORDERS_REQUEST, LIST_ORDERS_SUCCESS} from "../order/constants";
import {DEFAULT_ROWS_PER_PAGE} from "../consts";

export const createFeedbackReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_FEEDBACK_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case CREATE_FEEDBACK_SUCCESS: {
            return {
                ...state,
                feedback: action.payload,
                loading: false,
                error: null,
                message: "Feedback sent successfully"
            }
        };
        case CREATE_FEEDBACK_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const listOrderFeedbacksReducer = (state = {}, action) => {
    switch (action.type) {
        case LIST_ORDER_FEEDBACK_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case LIST_ORDER_FEEDBACK_SUCCESS:
            return {
                ...state,
                feedbacks: action.payload.data,
                countPages: Math.ceil(action.payload.listLength/ DEFAULT_ROWS_PER_PAGE),
                countRows: action.payload.listLength,
                loading: false,
                error: null,
            };
        case LIST_ORDER_FEEDBACK_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};