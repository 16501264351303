import {
    CREATE_TABLES_FAILURE,
    CREATE_TABLES_REQUEST,
    CREATE_TABLES_SUCCESS,
    DOWNLOAD_TABLE_QR_CODE_FAILURE,
    DOWNLOAD_TABLE_QR_CODE_REQUEST,
    DOWNLOAD_TABLE_QR_CODE_SUCCESS,
    GET_ORDER_FAILURE,
    GET_ORDER_REQUEST,
    GET_ORDER_SUCCESS,
    GET_RECEIPT_FAILURE,
    GET_RECEIPT_REQUEST,
    GET_RECEIPT_SUCCESS,
    LIST_ORDERS_FAILURE,
    LIST_ORDERS_REQUEST,
    LIST_ORDERS_SUCCESS,
    LIST_TABLES_FAILURE,
    LIST_TABLES_REQUEST,
    LIST_TABLES_SUCCESS,
    UPDATE_ORDER_FAILURE,
    UPDATE_ORDER_REQUEST,
    UPDATE_ORDER_SUCCESS
} from "./constants";
import {DEFAULT_ROWS_PER_PAGE} from "../consts";

export const listOrdersReducer = (state = {}, action) => {
    switch (action.type) {
        case LIST_ORDERS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case LIST_ORDERS_SUCCESS:
            return {
                ...state,
                orders: action.payload.data,
                countPages: Math.ceil(action.payload.listLength / DEFAULT_ROWS_PER_PAGE),
                countRows: action.payload.listLength,
                loading: false,
                error: null,
            };
        case LIST_ORDERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const listTablesReducer = (state = {}, action) => {
    switch (action.type) {
        case LIST_TABLES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case LIST_TABLES_SUCCESS:
            return {
                ...state,
                tables: action.payload.data,
                countPages: Math.ceil(action.payload.listLength / DEFAULT_ROWS_PER_PAGE),
                countRows: action.payload.listLength,
                loading: false,
                error: null,
            };
        case LIST_TABLES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getOrderReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_ORDER_SUCCESS:
            return {
                ...state,
                order: action.payload,
                loading: false,
                error: null,
            };
        case GET_ORDER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getReceiptViewReducer = (state = {}, action) => {
    switch(action.type) {
        case GET_RECEIPT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            }
        case GET_RECEIPT_SUCCESS:
            return {
                ...state,
                receipt: action.payload,
                loading: false,
                error: null
            }
        case GET_RECEIPT_FAILURE:
            return {
                ...state,
                loading: false,
                receipt: null,
                error: action.payload
            }
        default:
            return state;
    }
}

export const updateOrderReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case UPDATE_ORDER_SUCCESS:
            return {
                ...state,
                order: action.payload,
                success: true,
                loading: false,
                error: null,
            };
        case UPDATE_ORDER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const createTableReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_TABLES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case CREATE_TABLES_SUCCESS:
            return {
                ...state,
                table: action.payload,
                success: true,
                loading: false,
                error: null,
            };
        case CREATE_TABLES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const downloadTableQRCodeReducer = (state = {}, action) => {
    switch (action.type) {
        case DOWNLOAD_TABLE_QR_CODE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case DOWNLOAD_TABLE_QR_CODE_SUCCESS:
            return {
                ...state,
                table: action.payload,
                success: true,
                loading: false,
                error: null,
            };
        case DOWNLOAD_TABLE_QR_CODE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};