// ReviewModal.js
import React from 'react';
import BottomCallout from '../../../components/BottomCallout';

const ReviewModal = ({ isOpen, review, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-[#fff7e1] p-6 rounded-lg w-full max-w-lg">
                <h2 className="text-2xl font-bold mb-4 text-[#151e46]">Review Message</h2>
                <p className="mb-4 text-[#151E46]]">{review.comment}</p>
                <div className="flex justify-end">
                    <button
                        className="bg-[#F0532F] text-white px-4 py-2 rounded-md"
                        onClick={onClose}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ReviewModal;
