import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {Button, TextInput} from "@tremor/react";
import {useDispatch, useSelector} from "react-redux";
import {forgetPasswordEmailAction} from "../../redux/auth/actions";
import BottomCallout from "../../components/BottomCallout";
import { useHistory } from 'react-router-dom';


const ForgetPassEmail = (props) => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [showCallout, setShowCallout] = useState(false);

    const dispatch = useDispatch();
    const forgetPasswordEmailReducer = useSelector(state => state.forgetPasswordEmailReducer);

    const history = useHistory()

    useEffect(() => {
        if(forgetPasswordEmailReducer?.success === true) {
            setShowCallout(true);
            localStorage.setItem("forgetPassEmail",email)
            setTimeout(() => {
                setShowCallout(false);
                history.push('/forgot-pass-otp')
            }, 2000);
        }
        else if(forgetPasswordEmailReducer?.error){
            setShowCallout(true);
            setTimeout(() => {
                setShowCallout(false);
            }, 5000);
        }
    }, [forgetPasswordEmailReducer,dispatch]);

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleSubmit = () => {
        setError(null)
        if (!validateEmail(email)) {
            setError('Enter a valid email');
            setShowCallout(true);
            setTimeout(() => {
                setShowCallout(false);
            }, 5000);
            return;
        }
        dispatch(forgetPasswordEmailAction(email));
    };

    return (
        <div className="flex overflow-auto min-h-screen items-center" style={{backgroundColor: '#fff7e1'}}>
            <Helmet>
                <title>Serv Restaurant Interface</title>
                <meta property="og:title" content="Serv Restaurant Interface"/>
            </Helmet>
            <div className="w-1/2 flex items-center flex-col justify-start rounded-lg p-8"
                 style={{backgroundColor: '#fff7e1'}}>
                <div className="w-full flex items-start mb-8">
                </div>
                <div className='w-full flex flex-col items-center' style={{alignSelf: "center"}}>
                    <div className="w-full flex flex-col items-center mb-8">
                        <div className="w-full h-24 flex items-center justify-center">
                            <img
                                alt="logo"
                                src="/logos/serv-green.png"
                                className="text-[#40b06d] h-[500px] mb-28 text-5xl font-extrabold my-6"
                            />
                        </div>
                        <span className="text-gray-900 text-2xl font-bold mb-4">Forgot Your Password ?</span>
                        <span
                            className="text-gray-900 text-md text-center">Please enter your email address </span>
                    </div>
                    <div className="w-full flex flex-col items-center relative">
                        <div className="w-full max-w-md h-16 flex flex-col mb-4">
                            <label htmlFor="Email-Input" className="text-gray-900 text-lg ml-2 mb-1">Email</label>
                            <div className="relative border border-black rounded-lg bg-fff7e1">
                                <TextInput
                                    type="text"
                                    id="Email-Input"
                                    name="Email"
                                    required={true}
                                    placeholder="example@email.com"
                                    className="text-gray-900 h-[52px] border-0 w-full px-4 bg-transparent rounded-lg"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </div>


                        <Button
                            type="button"
                            className="mt-8 px-6 py-2 w-[150px] h-[50px] text-white rounded-lg"
                            style={{backgroundColor: '#F0532F'}}
                            onClick={handleSubmit}
                            loading={forgetPasswordEmailReducer?.loading}
                        >
                            Send OTP
                        </Button>
                    </div>
                </div>
            </div>

            <div className="w-1/2 h-screen flex items-center flex-col justify-center"
                 style={{backgroundColor: '#40b06d'}}>
                <img alt="image" src="/1%204(2)-1100h.png" className="absolute top-0 right-0 w-72 object-cover"/>
                <img alt="image" src="/11%202(2)-1100h.png"
                     className="absolute left-1/2 bottom-0 transform -translate-x-1/2 w-96 object-cover"/>


                <span className="text-[#fff7e1] text-5xl font-extrabold text-center mt-4 mb-4">tap, serv, eat, enjoy</span>
                <span className="text-gray-900 text-xl font-medium text-center whitespace-pre-line"
                      style={{color: "#151e46"}}>
                  Say goodbye to waiting and hello to a new era of dining ease with serv
                </span>
            </div>
            {showCallout && (error || forgetPasswordEmailReducer?.error || forgetPasswordEmailReducer?.message)  && (
                <BottomCallout
                    message={error || forgetPasswordEmailReducer?.error || forgetPasswordEmailReducer?.message}
                    variant={error || forgetPasswordEmailReducer?.error ? "error" : "success"}
                />
            )}
        </div>
    );
};

export default ForgetPassEmail;
