import { configureStore } from "@reduxjs/toolkit";

import {
  forgetPasswordEmailReducer,
  isLoggedInReducer,
  loginReducer,
  resetPasswordReducer,
  verifyOTPReducer
} from "./auth/reducers";
import {itemAvailabilityUpdateReducer, listMenuReducer, menuAddReducer, menuUpdateReducer} from "./menu/reducers";
import {createFeedbackReducer, listOrderFeedbacksReducer} from "./feedback/reducers";
import {
  getEstablishmentBranchReducer,
  getEstablishmentReducer,
  getMiscAnalyticsReducer, updateEstablishmentReducer
} from "./establishment/reducers";
import {
  createTableReducer, downloadTableQRCodeReducer,
  getOrderReducer,
  listOrdersReducer,
  listTablesReducer,
  updateOrderReducer,
  getReceiptViewReducer
} from "./order/reducers";

const store = configureStore({
  reducer: {
    //////////////////////////////// AUTH //////////////////////////////////
    loginReducer,
    forgetPasswordEmailReducer,
    verifyOTPReducer,
    resetPasswordReducer,
    isLoggedInReducer,

    //////////////////////////////// MENU //////////////////////////////////
    listMenuReducer,
    menuUpdateReducer,
    menuAddReducer,
    itemAvailabilityUpdateReducer,

    /////////////////////////////// FEEDBACK ///////////////////////////////
    createFeedbackReducer,
    listOrderFeedbacksReducer,

    ////////////////////////////// Establishment ///////////////////////////
    getEstablishmentReducer,
    getMiscAnalyticsReducer,
    getEstablishmentBranchReducer,
    updateEstablishmentReducer,

    ////////////////////////////// Orders ///////////////////////////
    listOrdersReducer,
    listTablesReducer,
    getOrderReducer,
    updateOrderReducer,
    createTableReducer,
    downloadTableQRCodeReducer,
    getReceiptReducer: getReceiptViewReducer
  },
});

export default store;
