import React from 'react';

const ConfirmationModal = ({ message, onConfirm, onCancel }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
            <div className="bg-[#FFF7E1] p-6 rounded-lg shadow-lg text-center w-1/3">
                <p className="text-2xl text-[#29333d] mb-4">{message}</p>
                <div className="flex justify-around">
                    <button
                        onClick={onCancel}
                        className="px-4 py-2 bg-[#FF6347] text-white font-semibold rounded-md"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={onConfirm}
                        className="px-4 py-2 bg-[#40b06d] text-white font-semibold rounded-md"
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;
