import React, {useEffect, useState} from 'react';
import { Dialog } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateOrderAction } from '../../../redux/order/actions';

const AddNewItemModal = ({ menuList, onClose }) => {
    const [selectedItem, setSelectedItem] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [customizations, setCustomizations] = useState([]);
    const [selectedChoices, setSelectedChoices] = useState({});

    const { id } = useParams();
    const dispatch = useDispatch();
    const { loading, success } = useSelector(state => state.updateOrderReducer);

    const handleItemChange = (e) => {
        const itemId = e.target.value;
        const item = menuList.flatMap(category => category.menuItems).find(item => item.id === parseInt(itemId));
        setSelectedItem(item);
        setCustomizations(item?.customization_options || []);
        setSelectedChoices({});
    };

    const handleCheckboxChange = (customizationId, choiceValue) => {
        setSelectedChoices(prev => {
            const updatedChoices = { ...prev };
            if (!updatedChoices[customizationId]) {
                updatedChoices[customizationId] = [];
            }

            if (updatedChoices[customizationId].includes(choiceValue)) {
                updatedChoices[customizationId] = updatedChoices[customizationId].filter(value => value !== choiceValue);
            } else {
                updatedChoices[customizationId].push(choiceValue);
            }

            return updatedChoices;
        });
    };

    const handleSubmit = () => {
        const body = {
            newItemParams: {
                menuItemID: selectedItem.id,
                quantity: parseInt(quantity),
                forms: {
                    answers: customizations.map(customization => ({
                        question: customization.name,
                        choices: selectedChoices[customization.id] || [],
                    }))
                }
            }
        };

        dispatch(updateOrderAction(id, body));
        if(success) {
        onClose();
        }
    };

    return (
        <Dialog open={true} onClose={onClose} className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen bg-black bg-opacity-50">
                <div className="rounded-lg bg-[#FFF7E1] overflow-hidden shadow-xl transform transition-all max-w-2xl w-full p-6">
                    <Dialog.Title className="text-2xl font-semibold text-[#29333d]">Add New Item</Dialog.Title>

                    <div className="mt-4">
                        <label className="block text-lg font-medium text-[#40B06D]">Menu Item</label>
                        <select
                            className="mt-1 border-2 block w-full pl-3 bg-[#FFF7E1] pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-[#40b06d] focus:border-[#40b06d] sm:text-lg rounded-md"
                            onChange={handleItemChange}
                        >
                            <option value="">Select a menu item</option>
                            {menuList?.map(category => (
                                category.menuItems?.map(item => (
                                    <option key={item?.id} value={item?.id}>{item?.name}</option>
                                ))
                            ))}
                        </select>
                    </div>

                    <div className="mt-4">
                        <label className="block text-lg font-medium text-[#40B06D]">Quantity</label>
                        <input
                            type="number"
                            className="mt-1 block text-lg border-2 w-full pl-5 pr-10 py-2 border-gray-300 focus:outline-none focus:ring-[#40b06d] focus:border-[#40b06d] sm:text-sm rounded-md"
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                        />
                    </div>

                    <br/>

                    {selectedItem && customizations.length > 0 && (
                        <div className="mt-6">
                            <h3 className="text-xl font-semibold text-[#29333d]">Customizations</h3>
                            {customizations?.map((customization, index) => (
                                <div key={customization.id} className="mt-4">
                                    <label className="block text-lg font-medium text-[#40B06D]">
                                        {customization.name}
                                        {customization.is_required && <span className="text-red-500">*</span>}
                                    </label>
                                    {customization.choices?.map((choice, idx) => (
                                        <div key={idx} className="mt-2">
                                            <input
                                                type="checkbox"
                                                id={`customization-${index}-${idx}`}
                                                name={`customization-${index}`}
                                                value={choice.answer}
                                                className="mr-2 text-xl"
                                                onChange={() => handleCheckboxChange(customization.id, choice.answer)}
                                            />
                                            <label htmlFor={`customization-${index}-${idx}`} className="text-[#151e46] text-lg">
                                                {choice.answer} (+EGP{choice.price})
                                            </label>
                                        </div>
                                    ))}
                                    <p className="text-sm text-gray-500">
                                        Max number of choices: {customization.max_number_of_choices}
                                    </p>
                                </div>
                            ))}
                        </div>
                    )}

                    <div className="mt-6 flex justify-end">
                        <button
                            className="px-4 py-2 bg-[#F0532F] text-white font-semibold rounded-md mr-2"
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                        <button
                            className="px-4 py-2 bg-[#40b06d] text-white font-semibold rounded-md"
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                            {loading ? 'Submitting...' : 'Submit'}
                        </button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default AddNewItemModal;
