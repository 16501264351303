import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import {useHistory, useLocation} from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import CustomizationOptions from "./components/CustomizationOptions";
import {listMenuAction, updateItemAvailability, updateMenuAction} from '../../redux/menu/actions';
import BottomCallout from '../../components/BottomCallout';
import './components/MenuItemEditView.css';
import {BsPencil} from "react-icons/bs";

const MenuItemEditView = (props) => {
  const location = useLocation();
  const history = useHistory();

  const [item, setItem] = useState(location.state.item);
  const [categoryName, setCategoryName] = useState(location.state.categoryName);
  useEffect(() => {
    if (location.state) {
      setItem(location.state.item);
      setCategoryName(location.state.categoryName);
    }
  }, [location.state]);


  const [name, setName] = useState(item.name);
  const [price, setPrice] = useState(item.price);
  const [description, setDescription] = useState(item.description);
  const [availability, setAvailability] = useState(item.is_available_toggle);
  const [showCallout, setShowCallout] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [opensAt, setOpensAt] = useState(item.opens_at);
  const [closesAt, setClosesAt] = useState(item.closes_at);
  const [customizationOption, setCustomizationOption] = useState({
    name: undefined,
    menuItemId: item.id,
    isRequired: false,
    choices: [],
    maxNumberOfChoices: 0,
  });
  const [customizationID, setCustomizationID] = useState(undefined);


  const dispatch = useDispatch();
  const menuUpdate = useSelector(state => state.menuUpdateReducer);
  const itemAvailabilityUpdateReducer = useSelector(state => state.itemAvailabilityUpdateReducer);
  const { loading, error, menu } = menuUpdate;

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setImageFile(file);
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name || !price || !description) {
      alert("All fields are required.");
      return;
    }
    const updateParams = {
      name: categoryName,
      branchID: parseInt(localStorage.getItem("branchID")),
      item: {
        name,
        imageURL: item.imageURL,
        description,
        price,
        opensAt,
        closesAt
      },
      itemID: item.id,
    };

    dispatch(updateMenuAction(item.category_id, updateParams,imageFile));
  };


  useEffect(() => {
    if (menu && menuUpdate.success) {
      const category = menu.find((i) => Number(item.category_id) === Number(i.id));
      const updatedItem = category.menuItems.find((i) => item.id == i.id);
      if (updatedItem) {
        history.replace({
          ...location,
          state: {
            ...location.state,
            item: updatedItem
          }
        });
      }
      setShowCallout(true);
      setTimeout(() => {
        setShowCallout(false);
      }, 3000);
    }
  }, [menuUpdate]);

  useEffect(() => {
    if (itemAvailabilityUpdateReducer && itemAvailabilityUpdateReducer.success) {
      history.replace({
        ...location,
        state: {
          ...location.state,
          item: {...item, is_available_toggle: itemAvailabilityUpdateReducer.item.is_available_toggle}
        }
      });
    }
    setShowCallout(true);
    setTimeout(() => {
      setShowCallout(false);
    }, 3000);
  }, [itemAvailabilityUpdateReducer,dispatch]);


  return (
      <div className="flex h-screen font-sans text-lg bg-[#fff7e1]">
        <Helmet>
          <title>Edit Item - Serv Restaurant Interface</title>
          <meta property="og:title" content="Edit Item - Serv Restaurant Interface" />
        </Helmet>

        <Sidebar />

        <div className="ml-80 flex-1 p-8 overflow-auto flex mb-16 justify-center">
          <div className="rounded-lg bg-[#fff7e1] p-8 w-full h-auto">
            <div className="menu-item-container">
              {/* Left: Image */}
              <div className="menu-item-image lg:w-2/5">
                <div className='relative h-full'>
                <img
                    alt="Item"
                    src={imageUrl ? imageUrl : item.image_url}
                    className="w-full h-full lg:h-full object-cover rounded-lg shadow-lg"
                />
                <div className="mt-2">
                  <button
                      onClick={() => {
                        document.getElementById('imageUpload').click();
                      }}
                      className="absolute top-2 right-2 hover:bg-[#151e46] text-white font-bold py-2 px-4 rounded"
                  >
                    <BsPencil className="text-lg"/>
                  </button>
                </div>
                </div>
                {/* Hidden file input */}
                <input
                    type="file"
                    id="imageUpload"
                    accept="image/*"
                    className="hidden"
                    onChange={handleImageChange}
                />
              </div>

              {/* Right: Information */}
              <form onSubmit={handleSubmit} className="menu-item-info flex-1 lg:ml-8">
                <h2 className="text-4xl mb-4 mt-0 font-bold text-[#151e46]">{item.name}</h2>

                <div className="mb-4">
                  <div className="mb-2">
                    <span className="text-[#151e46] font-bold">Category</span>
                  </div>
                  <div className="flex items-center mb-2">
                    <input
                        type="text"
                        className="p-2 bg-[#fff7e1] border-2 border-gray-300 rounded-md focus:outline-none focus:border-[#40b06d] w-full"
                        value={categoryName}
                        readOnly
                        required={true}
                    />
                  </div>

                  <div className="mb-2">
                    <span className="text-[#151e46] font-bold">Name</span>
                  </div>
                  <div className="flex items-center mb-2">
                    <input
                        type="text"
                        className="p-2 bg-[#fff7e1] border-2 border-gray-300 rounded-md focus:outline-none focus:border-[#40b06d] w-full"
                        value={name}
                        required={true}
                        onChange={(e) => setName(e.target.value)}
                    />
                  </div>

                  <div className="mb-2">
                    <span className="text-[#151e46] font-bold">Price</span>
                  </div>
                  <div className="flex items-center mb-2">
                    <input
                        type="number"
                        className="p-2 bg-[#fff7e1] border-2 border-gray-300 rounded-md focus:outline-none focus:border-[#40b06d] w-full"
                        value={price}
                        min="0"
                        step="0.01"
                      required={true}
                      onChange={(e) => setPrice(parseFloat(e.target.value) || 0)}
                      />
                  </div>
                </div>

                <div className="mb-4">
                  <label htmlFor="description" className="block text-[#151e46] font-bold mb-2">Description</label>
                  <textarea
                      id="description"
                      value={description}
                      required={true}
                      onChange={(e) => setDescription(e.target.value)}
                      className="p-2 bg-[#fff7e1] h-24 border-2 border-gray-300 rounded-md focus:outline-none focus:border-[#40b06d] w-full"
                  ></textarea>
                </div>

                <div className={'grid grid-cols-2'}>
                  <div>
                    <div className="mb-2">
                      <span className="text-[#151e46] font-bold">Starting Time</span>
                    </div>
                    <div className="flex items-center mb-2">
                      <input
                          type="time"
                          className="p-2 bg-[#fff7e1] border-2 border-gray-300 rounded-md focus:outline-none focus:border-[#40b06d] w-full"
                          value={opensAt}
                          required={true}
                          onChange={(e) => setOpensAt(e.target.value)}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="mb-2">
                      <span className="text-[#151e46] font-bold">Closing Time</span>
                    </div>
                    <div className="flex items-center mb-2">
                      <input
                          type="time"
                          className="p-2 bg-[#fff7e1] border-2 border-gray-300 rounded-md focus:outline-none focus:border-[#40b06d] w-full"
                          value={closesAt}
                          required={true}
                          onChange={(e) => setClosesAt(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex items-end justify-between">
                  <div className="flex items-center">
                    {localStorage.getItem('branchID') !== "0" && <>
                      <img alt="image"
                           onClick={(event) => {
                             dispatch(updateItemAvailability(localStorage.getItem("branchID"), item.id, !availability));
                             setAvailability(!availability)
                           }}
                           src={availability ? '/toggle-on-svgrepo-com.svg' : '/toggle-off-svgrepo-com.svg'}
                           className="w-12 h-12 mr-2"/>
                      <span
                          className="text-[#151e46] text-sm">{availability ? 'Available' : 'Unavailable'}</span>
                    </>}
                  </div>
                  <div className="flex items-center">
                    <button
                        type="submit"
                        className="bg-[#40b06d] text-white py-2 px-4 rounded-md mr-2"
                        disabled={loading}
                    >
                      {loading ? 'Saving...' : 'Save'}
                    </button>
                  </div>
                </div>
              </form>
            </div>


            {error && (
                <BottomCallout
                    message={error}
                    variant="error"
                />
            )}
            {showCallout && menuUpdate.success && (
                <BottomCallout
                    message="Menu updated successfully"
                    variant="success"
                />
            )}

            <hr/>

            <CustomizationOptions
                data={item.customization_options}
                customizationOption={customizationOption}
                setCustomizationOption={setCustomizationOption}
                customizationID={customizationID}
                setCustomizationID={setCustomizationID}
                item={item}
                categoryName={categoryName}
            />
          </div>
        </div>
      </div>
  );
};

export default MenuItemEditView;
