export const LIST_MENU_REQUEST = 'LIST_MENU_REQUEST'
export const LIST_MENU_SUCCESS = 'LIST_MENU_SUCCESS'
export const LIST_MENU_FAIL = 'LIST_MENU_FAIL'

export const MENU_UPDATE_REQUEST = 'MENU_UPDATE_REQUEST';
export const MENU_UPDATE_SUCCESS = 'MENU_UPDATE_SUCCESS';
export const MENU_UPDATE_FAIL = 'MENU_UPDATE_FAIL';

export const MENU_ADD_REQUEST = 'MENU_ADD_REQUEST';
export const MENU_ADD_SUCCESS = 'MENU_ADD_SUCCESS';
export const MENU_ADD_FAIL = 'MENU_ADD_FAIL';

export const ITEM_AVAILABILITY_UPDATE_REQUEST = 'ITEM_AVAILABILITY_UPDATE_REQUEST';
export const ITEM_AVAILABILITY_UPDATE_SUCCESS = 'ITEM_AVAILABILITY_UPDATE_SUCCESS';
export const ITEM_AVAILABILITY_UPDATE_FAIL = 'ITEM_AVAILABILITY_UPDATE_FAIL';
