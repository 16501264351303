import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { resetPasswordAction } from '../../redux/auth/actions';
import BottomCallout from "../../components/BottomCallout";
import { useHistory } from 'react-router-dom';
import {Button, TextInput} from '@tremor/react';
import {checkPassword} from "../../utils/authUtils";

const ResetPass = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const [showCallout, setShowCallout] = useState(false);

    const dispatch = useDispatch();
    const resetPasswordReducer = useSelector(state => state.resetPasswordReducer);

    const history = useHistory();

    useEffect(() => {
        if (resetPasswordReducer?.success) {
            setShowCallout(true);
            setTimeout(() => {
                setShowCallout(false);
                history.push('/dashboard');
            }, 3000);
        } else if (resetPasswordReducer?.error) {
            setShowCallout(true);
            setTimeout(() => {
                setShowCallout(false);
            }, 5000);
        }
    }, [resetPasswordReducer, history]);

    const handleSubmit = () => {
        setError('');
        const error = checkPassword(password, confirmPassword);

        if (error) {
            setError(error);
            setShowCallout(true);
            setTimeout(() => {
                setShowCallout(false);
            }, 5000);
            return;
        }

        dispatch(resetPasswordAction(password, confirmPassword));
    };

    return (
        <div className="flex overflow-auto min-h-screen items-center" style={{ backgroundColor: '#fff7e1' }}>
            <div className="text-green-600 text-4xl font-extrabold absolute top-0 left-0 ml-6 mt-6 z-10">SERV</div>
            <Helmet>
                <title>ResetPass - Serv Restaurant Interface</title>
                <meta property="og:title" content="ResetPass - Serv Restaurant Interface" />
            </Helmet>

            <div className="w-1/2 flex items-center flex-col justify-start rounded-lg p-8" style={{ backgroundColor: '#fff7e1' }}>
                <div className="w-full flex items-start mb-8"></div>
                <div className='w-full flex flex-col items-center'>
                    <div className="w-full flex flex-col items-center mb-8">
                        <span className="text-gray-900 text-4xl font-bold mb-4">Reset Password</span>
                        <span className="text-gray-900 text-lg">Please enter your new password below.</span>
                    </div>

                    <div className="w-full max-w-md h-16 flex flex-col my-4">
                        <label htmlFor="Password-Input" className="text-gray-900 text-lg ml-2 mb-1">New Password</label>
                        <div className="relative border border-black rounded-lg px-4 bg-fff7e1 flex items-center">
                            <TextInput
                                type={"password"}
                                id="Password-Input"

                                name="Password"
                                required={true}
                                placeholder="********"
                                className="text-gray-900 border-0 h-[52px] w-full bg-transparent rounded-lg"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="w-full max-w-md h-16 flex flex-col my-4">
                        <label htmlFor="Confirm-Password-Input" className="text-gray-900 text-lg ml-2 mb-1">Confirm Password</label>
                        <div className="relative border border-black rounded-lg bg-fff7e1 px-4 flex items-center">
                            <TextInput
                                type={'password'}
                                id="Confirm-Password-Input"
                                name="ConfirmPassword"
                                required={true}
                                placeholder="********"
                                className="text-gray-900 border-0 h-[52px] w-full bg-transparent rounded-lg"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="w-full max-w-md h-16 flex flex-col mb-4 mt-4">
                        <span className="text-gray-900 text-sm">Passwords must be at least 8 characters long and include a combination of letters, numbers, and symbols.</span>
                    </div>

                    <Button
                        className="mt-8 px-6 py-2 w-[150px] h-[50px] text-white rounded-lg"
                        style={{ backgroundColor: '#F0532F' }}
                        onClick={handleSubmit}
                        loading={resetPasswordReducer?.loading}
                    >
                        Confirm
                    </Button>
                </div>
            </div>

            <div className="w-1/2 h-screen flex items-center flex-col justify-center" style={{ backgroundColor: '#40b06d' }}>
                <img alt="image" src="/1%204(2)-1100h.png" className="absolute top-0 right-0 w-72 object-cover" />
                <img alt="image" src="/11%202(2)-1100h.png" className="absolute left-1/2 bottom-0 transform -translate-x-1/2 w-96 object-cover" />
                <span className="text-white text-5xl font-extrabold text-center mt-4 mb-4">tap, serv, eat, enjoy</span>
                <span className="text-gray-900 text-xl font-medium text-center whitespace-pre-line" style={{ color: "#151e46" }}>
                    Say goodbye to waiting and hello to a new era of dining ease with serv
                </span>
            </div>
            {showCallout && (error || resetPasswordReducer?.error || resetPasswordReducer?.message) && (
                <BottomCallout
                    message={error || resetPasswordReducer?.error || resetPasswordReducer?.message}
                    variant={error || resetPasswordReducer?.error ? "error" : "success"}
                />
            )}
        </div>
    );
};

export default ResetPass;
