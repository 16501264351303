export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';

export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAIL = 'USER_LOGOUT_FAIL';

export const USER_FORGET_PASS_EMAIL_REQUEST = 'USER_FORGET_PASS_EMAIL_REQUEST';
export const USER_FORGET_PASS_EMAIL_SUCCESS = 'USER_FORGET_PASS_EMAIL_SUCCESS';
export const USER_FORGET_PASS_EMAIL_FAIL = 'USER_FORGET_PASS_EMAIL_FAIL';

export const VERIFY_OTP_REQUEST = 'VERIFY_OTP_REQUEST';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAIL = 'VERIFY_OTP_FAIL';

export const RESET_PASS_REQUEST = 'RESET_PASS_REQUEST';
export const RESET_PASS_SUCCESS = 'RESET_PASS_SUCCESS';
export const RESET_PASS_FAIL = 'RESET_PASS_FAIL';

export const IS_LOGGED_IN_PASS_REQUEST = 'IS_LOGGED_IN_PASS_REQUEST';
export const IS_LOGGED_IN_PASS_SUCCESS = 'IS_LOGGED_IN_PASS_SUCCESS';
export const IS_LOGGED_IN_PASS_FAIL = 'IS_LOGGED_IN_PASS_FAIL';