import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { isLoggedInAction } from '../../redux/auth/actions';
import { protectedRoutes } from "../../consts";
import { matchProtectedRoute } from "../../utils/authUtils";

const AuthWrapper = ({ children }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const isLoggedInReducer = useSelector(state => state.isLoggedInReducer);
    const [isEndpointCalled, setIsEndpointCalled] = useState(false);

    // Call the isLoggedInAction only once when the component mounts if it's a protected route
    useEffect(() => {
        if (matchProtectedRoute(location.pathname, protectedRoutes)) {
            dispatch(isLoggedInAction());
            setIsEndpointCalled(true);
        }
    }, [dispatch, location.pathname]);

    useEffect(() => {
        if (isEndpointCalled && !isLoggedInReducer.loading) {
            if (!isLoggedInReducer.isAuthorised || !localStorage.getItem('userInfo')) {
                history.push('/');
            }
        }
    }, [isLoggedInReducer.isAuthorised, isLoggedInReducer.loading, isEndpointCalled, location.pathname, history]);

    return children;
};

export default AuthWrapper;
